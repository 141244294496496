import { GafSubmissionState } from '@/types'

export default {
  Login: {
    ImageAlt: 'A heart with the Gift Aid it mark inside',
    ImageAriaLabel: 'Gift Aid main image',
    Link: {
      Header: 'Swiftaid for charities',
      SubHeader: 'Enter your email below to continue',
    },
    Code: {
      Header: "Let's try something else",
      SubHeader: 'Enter your email below and receive a unique code to continue',
    },
    EmailSent: {
      ImageAlt: 'An email being opened',
      Header: 'Check your email to continue',
      SubHeaderForLink: 'We have sent a link to',
      SubHeaderForCode: 'We have sent a unique 6-digit code to',
      ChangeEmail: 'Use a different email',
      JunkNotice:
        'Can’t find the email? Please check your spam or junk mail folder',
    },
    EmailForm: {
      AriaLabel: 'Enter your email',
      EmailInput: {
        Label: 'Your email',
        Placeholder: 'your.email@address.com',
      },
      SubmitButton: {
        Text: 'Continue',
      },
    },
    CodeForm: {
      AriaLabel: 'Enter your code',
      CodeInput: {
        Label: 'Please enter your unique code',
        Placeholder: '000000',
      },
      CodeError: 'Code not working, please try again.',
      SubmitButton: {
        Text: 'Continue',
      },
    },
    AgreementNotice: {
      Part1: 'By clicking continue, you agree to our',
      TermsLinkText: 'Terms',
      Part2: 'and',
      PrivacyLinkText: 'Privacy Policy',
    },
    HavingTroubleNotice: {
      Question: 'Having trouble signing in?',
      UseCodeLinkText: 'Use a code instead',
      UseEmailLinkText: 'Cancel',
    },
    ResendCodeOrLink: {
      Button: {
        ResendLink: 'Resend link',
        ResendCode: 'Resend code',
        ActiveText: 'Resending',
      },
    },
    Details: {
      Header: 'Your name',
      EditHeader: 'Your details',
      SubHeader: 'Please let us know your name before you continue',
      Form: {
        AriaLabel: 'Please enter your full name',
        FirstNameInput: {
          Label: 'First name(s)',
          Placeholder: 'First name(s)',
        },
        LastNameInput: {
          Label: 'Last name',
          Placeholder: 'Last name',
        },
        Button: {
          AriaLabel: 'Press enter to continue',
          Text: 'Continue',
          SavingText: 'Saving...',
        },
        CancelButtonText: 'Cancel',
      },
    },
  },
  HMRCAuthorisation: {
    Header: 'HMRC Authorisation',
    SubHeader:
      'Enter your charity’s details below to authorise Swiftaid as a Gift Aid nominee with HM Revenue and Customs (HMRC)',
    Form: {
      AriaLabel: 'HMRC authorisation form',
      BackButton: 'Go back',
      SubmitButton: 'Accept',
      Fields: {
        CharityName: {
          Label: 'Official name of charity',
          Placeholder: 'Official name of charity',
        },
        CharityRole: {
          Label: 'Your role within the charity',
          Placeholder: 'E.g. Treasurer',
        },
        CharityReference: {
          Label: 'HMRC charity reference',
          Placeholder: 'XN00000',
        },
        CharityWebsite: {
          Label: 'Charity website',
          Placeholder: 'https://www.example.com',
        },
        CharityRegulator: {
          Label: 'Charity regulator',
        },
        RegulatorNumber: {
          Label: 'Charity regulator number',
          Placeholder: '1234567',
        },
        RegulatorDescription: {
          Label: 'Please provide details of your regulator',
          Placeholder: 'Alternative regulator details',
        },
      },
    },
    HelpModal: {
      CharityName: {
        Title: 'Official name of charity',
        Body: 'This is the name of your Charity, Community Amateur Sports Club (CASC) or other organisation as known by HMRC and entitled to UK charity tax reliefs.',
      },
      CharityRole: {
        Title: 'Your role within the charity',
        Body: 'Please let us know your current role within the charity.',
      },
      CharityReference: {
        Title: 'HMRC charity reference',
        Body: 'This is the reference that your organisation was given when it registered with HMRC. It begins with a letter.',
      },
      CharityWebsite: {
        Title: "Your charity's website",
        Body: 'This helps us get to know your cause a little more.',
      },
      CharityRegulator: {
        Title: 'Charity regulator',
        Body: 'If your charity is registered with a UK charity regulator then you must select the name of that regulator from the drop-down list. If your charity is registered with a non-UK charity regulator then please select Other. If the charity is not registered with one of these UK regulators or is not registered with a non-UK regulator then select None. Community Amateur Sports Clubs should always select None from the drop-down list. If dual registered, please include a registration number you have used in previous correspondence with HMRC or complete and submit a change of details form (ChV1) with this information before you continue.',
      },
      RegulatorNumber: {
        Title: 'Charity regulator number',
        Body: 'Where you have selected a regulator from the drop-down list you must enter the registration number the regulator issued to you. The registration number from the regulator will be on any paperwork received from that regulator. If the charity is dual registered, please include a registration number you have used in previous correspondence with HMRC or complete and submit a change of details form (ChV1) with this information before you continue.',
      },
      RegulatorDescription: {
        Title: 'Non-UK charity regulator',
        Body: 'If you are registered with a non-UK charity regulator please provide full details of that regulator.',
      },
    },
    DeclarationBox: {
      Header: 'Declaration',
      Declaration: {
        Para1:
          'I {0} confirm that the information given above is correct and complete to the best of my knowledge and belief, and I hold the role of authorised official or responsible person within the organisation.',
        Para2:
          'I confirm that the organisation appoints Swiftaid to process Gift Aid on all donations deemed eligible by our charity and will not claim, or allow any other third party to claim, any Gift Aid benefit (including GASDS) on these donations without first confirming with Swiftaid.',
        Para3:
          'I confirm on behalf of the organisation, the acceptance of Swiftaid’s Terms and Conditions found at {0}',
      },
    },
    Conflict: {
      ImageAlt: 'A discarded banana skin on the floor with an unhappy face',
      Header: 'Oops!',
      SubHeader:
        'Sorry, this charity appears to be registered with Swiftaid already.',
      Button: 'Return home',
    },
    Complete: {
      ImageAlt: 'HMRC form icon',
      Header: 'Your authorisation request will be sent to HMRC',
      SubHeader:
        'Next we need to identify donations you’d like Swiftaid to boost with Gift Aid. Continue to your charity dashboard to add a donation source.',
      ContinueButton: 'Continue to dashboard',
      ReturnButton: 'Return home',
    },
  },
  Dashboard: {
    Header: 'Your registered charities',
    WelcomeTitle: 'Welcome to Swiftaid!',
    CharityListAriaLabel: 'A list of your charities',
    CharityPanel: {
      AriaLabel: 'Charity item',
      LinkText: 'View charity dashboard',
      NotificationsAriaLabel: 'Amount of notifications',
    },
    RegisterPanel: {
      Header: 'Register a charity',
      SubHeader: 'Get your charity up and running with Gift Aid on donations.',
      ImageAlt: 'Forms and a card terminal',
      LinkText: 'Get started',
      AriaLabel: 'Charity registration section',
    },
    RegisterCharityModal: {
      ModalTitle: 'Register your charity',
      Header: 'Before you continue',
      Text: {
        Part1:
          'Your organisation must already be registered with HM Revenue and Customs (HMRC)',
        Part2: 'Click here to find out more',
        Part3:
          'You must hold the role of authorised official or responsible person for your organisation',
      },
      Button: "OK, let's go",
    },
  },
  Charity: {
    GiftAid: {
      Summary: {
        AriaLabel: 'Donations summary row',
        TotalDeclarations: {
          AriaLabel: 'Total declarations',
          Desc: 'Total Declarations',
        },
        TotalDonations: {
          AriaLabel: 'Total donations in pounds',
          Desc: 'Total Donated',
        },
        TotalGiftAid: {
          AriaLabel: 'Total Gift Aid in pounds',
          Desc: 'Total Gift Aid',
        },
      },
      ClaimsTable: {
        AriaLabel: 'List of claims',
        Header: 'Claims',
        Field1: 'Date',
        Field2: 'Declarations',
        Field3: 'Gift Aid',
        Field4: 'Status',
        DetailsLink: {
          Text: 'Details',
          AriaLabel: 'Details about this claim',
        },
      },
      StatusLabel: {
        AriaLabel: 'Status label',
        FiledStatus: 'Filed',
        BuildingStatus: 'Building',
        ProcessingStatus: 'Processing',
      },
      Claim: {
        Summary: {
          BackButton: {
            AriaLabel: 'Back to charity',
            Text: 'Go back',
          },
          ClaimId: 'Claim ID',
          InvoiceId: 'Invoice ID',
          FiledDate: 'Filed Date',
          Declarations: 'Declarations',
          Adjustments: 'Adjustments',
          Donated: 'Donated',
          GiftAid: 'Gift Aid',
          Status: 'Status',
        },
        DeclarationsTable: {
          AriaLabel: 'List of declarations',
          Header: 'Declarations',
          Field1: 'Date',
          Field2: 'Time',
          Field3: 'Donation Amount',
          Field4: 'Gift Aid',
          NoDeclarationsMessage: 'No declarations found',
          PaginationLinksAriaLabel: 'Pagination links',
        },
      },
    },
    DonationSources: {
      CancelButton: 'Cancel adding source',
      AddButton: 'Add donation source',
      DetailsModal: {
        Title: 'Donation sources',
        Button: 'Done',
        SourceNameEditButton: 'Edit name',
        Authorisation: {
          Header: 'Authorisation',
          Desc: 'Swiftaid is authorised to process Gift Aid on all payments made through this donation source, between the following start and end dates.',
          StartDate: 'Start date',
          EndDate: 'End date',
          SetButton: 'Set',
          SetButtonAriaLabel: 'Confirm the date to end authorisation',
          ConfirmDateButton: 'Confirm date',
          CancelButton: 'Cancel',
          CancelButtonAriaLabel: 'Cancel selecting end date',
          DatePicker: {
            Paragraph:
              'When would you like Swiftaid to stop claiming Gift Aid through this source?',
            AriaLabel: 'Authorisation end date calendar section',
          },
        },
        Details: {
          TotalGiftAidSection: {
            AriaLabel: 'Total Gift Aid raised section',
            Header: 'Total Gift Aid raised',
          },
          CustomerReferenceSection: {
            AriaLabel: 'Customer reference section',
            Header: 'Customer reference',
          },
          FeesSection: {
            AriaLabel: 'Fees section',
            Header: 'Fees',
            Notice: 'Contact us to let us know if your fees change',
          },
          CustomerReference: 'Customer reference',
          Fees: 'Fees',
          FeesChangeNotification:
            'Contact us to let us know if your fees change',
          FeesChangeNotificationLinkText: 'click here to let us know',
        },
      },
      DonationSourceSetup: {
        AriaLabel: 'Donation source setup',
        Authorise: {
          Instructions:
            'Please read and agree to the following statement to authorise Swiftaid to collect Gift Aid on donations made through this provider',
          HighlightedText1:
            'I {0} confirm that all payments taken through {1} meet the Gift Aid qualifying requirements set by HMRC.',
          HighlightedText2:
            'I confirm that the organisation appoints Swiftaid to process Gift Aid on all eligible donations through this donation source and will not claim, or allow any other third party to claim, any Gift Aid benefit (including GASDS) on these donations without first confirming with Swiftaid.',
          Para1: {
            Part1: 'I',
            Part2: 'confirm that all payments taken through',
            Part3: 'meet the Gift Aid qualifying requirements set by HMRC.',
          },
          Para2:
            'I confirm that the organisation appoints Swiftaid to process Gift Aid on all eligible donations through this donation source and will not claim, or allow any other third party to claim, any Gift Aid benefit (including GASDS) on these donations without first confirming with Swiftaid.',
        },
        Confirmation: {
          Header: 'That’s it for now 👍',
          Para: 'We’ll confirm the information you have supplied with {0} and let you know when it is Gift Aid ready!',
        },
        ContactlessUpgradeNotice: {
          Para1:
            'We are currently making improvements to our GoodBox contactless Gift Aid solution.',
          Para2:
            'We’ll be in touch as soon as you are able to add this source and benefit from automated Gift Aid through your GoodBox terminals. Thank you for your patience while we make these changes.',
          Para3: 'Learn more about the upgrade',
        },
        Details: {
          InputLabel: '{providerName} customer reference',
          HelpIconTitle: 'Click for help finding this',
          ConfirmationInstructions:
            'Please confirm you are happy for Swiftaid to share and request the following technical information with {providerName}:',
          InformationSharedWithProviderTitle:
            'Information Swiftaid will share with {providerName}',
          InformationSharedFromProviderTitle:
            'Information {providerName} will share with Swiftaid',
        },
        DonationSourceSetup: {
          Header: 'Set up a donation source for Gift Aid',
          Instruction: 'Please select your provider',
          ProviderImageAlt: 'Donation source provider logo',
          ProviderAddButton: 'Add',
        },
        DonationSourceSetupModal: {
          Title: 'Add a donation source',
          ProviderLogoAriaLabel: 'Donation source provider logo',
          DoneButton: 'Done',
          AgreeButton: 'Agree and authorise',
          ConfirmButton: 'Confirm',
          CancelButton: 'Cancel',
        },
        EligibilityCheck: {
          Para: {
            Part1: 'Will any of your',
            Part2:
              'donation sources be used to collect payments for tickets (raffles, events and auctions) or goods and services (including ‘experience’ days)?',
          },
          RadioLabelForYes:
            'Yes, these donation sources will accept payment for goods and services.',
          RadioLabelForNo:
            "No, these donation sources will be used exclusively for 'freewill' donations.",
          InEligibilityNotice: {
            Header:
              'Sorry, your donation source won’t work with Swiftaid at this time 😞',
            Para: 'We can only process Gift Aid on donation sources that take freewill donations only. As soon as we support multi-purpose donation sources we’ll let you know! Sorry for any inconvenience this may have caused.',
          },
        },
        ErrorNotification: {
          Part1: '😖 Something went wrong.',
          Part2: 'Please',
          Part3: 'try again',
          Part4: 'or',
          Part5: 'start over',
          Part6: 'Try adding a different one.',
          Para1: 'Please {0}, or {1}.',
        },
        ProgressMeter: {
          EligibilityCheck: 'Eligibility check',
          Details: 'Details',
          Authorise: 'Authorise',
        },
      },
      ManualAddSourceModal: {
        Title: 'Add donation source',
        ImageAlt: 'A donation terminal',
        Header: 'Authorise a donation source',
        CloseButton: 'Close',
        Instructions: {
          Part1:
            'A donation source (e.g. contactless terminal) needs to be authorised before Swiftaid can begin processing Gift Aid on donations made through it. This is done by downloading and filling in our donation source authorisation form.',
          Part2: 'For more information and guidance please',
          Part3: 'read our DSA3 form guide',
          Part4: 'Please email your completed DSA3 form to',
          Part5: ', we’ll let you know by email once everything is good to go!',
        },
        DownloadFormButton: {
          AriaLabel: 'A button to download the DSA1 form',
          Text: 'Download authorisation form',
        },
      },
      SourceItem: {
        AriaLabel: 'Donation source',
        GiftAid: 'Gift Aid',
        DetailsLinkText: 'Details',
        State: {
          Active: 'Active',
          Pending: 'Awaiting first donation',
          Inactive: 'Inactive',
          Setup: 'Setup in progress',
        },
        ImageAlt: '{provider} logo',
      },
    },
    FindGiftAid: {
      Active: {
        ImageAlt: 'A spade digging into the ground with hearts coming out',
        Title: 'Find Missed Gift Aid',
        LeadPara:
          'Easily upload your charity’s donations from the current tax year, and we’ll cross-check them against millions of UK taxpayers to identify any missed Gift Aid.',
        Section1: {
          Title: '1. Download Spreadsheet Template',
          Description:
            'Download the template in CSV or ODS format. It includes specific information your charity needs to collect for each donation.',
          DownloadTemplateText: 'Download',
          DownloadTemplateHref:
            'https://static.swiftaid.co.uk/content/templates/Swiftaid_Gift_Aid_Finder_Template.zip',
        },
        Section2: {
          Title: '2. Add Donation Details',
          Description:
            'The more accurate information you add to the template, the higher the chances of a successful Gift Aid match.',
          LinkText: 'View donation guidelines',
          LinkHref: '#donationGuidelinesModal',
        },
        Section3: {
          Title: '3. Upload Your Spreadsheet',
          Description:
            'Ready to submit? Upload your completed spreadsheet below to begin matching your donations with millions of UK taxpayers.',
          LinkText: 'Upload your spreadsheet',
          LinkHref: '#uploadGafSubmissionsModal',
        },
        DonationGuidelinesModal: {
          Title: 'Donation guidelines',
          DonationsToIncludeSection: {
            Title: 'Donations to include',
            para1:
              'Only include donations received within the current tax year: {yearStart} to {yearEnd}.',
            para2: 'Only include donations you authorise Swiftaid to Gift Aid.',
            para3:
              'Make sure all donations meet HMRC’s qualifying requirements for Gift Aid. {link}',
            LinkText: 'View HMRC requirements',
            LinkHref:
              'https://www.gov.uk/claim-gift-aid/what-you-can-claim-it-on',
          },
          DonationsToExcludeSection: {
            Title: 'Donations NOT to include',
            para1: 'Don’t include donations received before {yearStart}.',
            para2:
              'Don’t include donations where Gift Aid was claimed through the Gift Aid Small Donations Scheme (GASDS).',
            para3:
              'Don’t include donations where Gift Aid has already been claimed, including by your charity or a third party (e.g. platforms like JustGiving which may add Gift Aid retrospectively).',
          },
          youtubeVideoDescription:
            'If you need extra guidance filling in the spreadsheet please watch the following video:',
          youtubeVideoUrl:
            'https://www.youtube-nocookie.com/embed/Tn7pg8OPVDY?rel=0',
          CloseButtonText: 'Close',
        },
        UploadGafSubmissionModal: {
          Title: 'Upload your spreadsheet',
          Description:
            'After you upload your spreadsheet, Swiftaid will review it for errors and notify you if any changes are needed. Once finalised, we’ll provide your charity with the results before processing any Gift Aid claims.',
          SecureNotice:
            'Your file and data are protected with us. To learn more about our security practices and how we manage your information, {0}.',
          LinkText: 'please visit our security page',
          LinkHref: 'https://www.swiftaid.co.uk/security/',
          DropInstruction: 'Drop your CSV or ODS file here',
          ClickInstruction: 'Click here to select a file',
          ErrorInstruction: 'Please try again',
          FileLimitationNotice: 'CSV or ODS files only. 50MB max.',
          ReleaseInstruction: 'Release to drop file here',
          ErrorMessageType: 'File type must be CSV or ODS',
          ErrorMessageMaxSize: 'File size is too large',
          ErrorMessageMinSize: 'File size is too small',
          CloseButtonText: 'Cancel',
          SubmitButtonText: 'Submit',
          RemoveButtonText: 'Remove',
          UploadProgressTitle: 'Uploading',
          UploadErrorTitle: 'Error uploading file',
          UploadErrorText: 'Something went wrong, please try again',
          ResetButtonText: 'Reset',
          UploadSuccessTitle: 'Spreadsheet received',
          UploadSuccessText:
            'Your spreadsheet has been received. We’ll review it further and contact you if any changes are needed.',
          UploadSuccessButtonText: 'Close',
          WarrantSection: {
            Confirmation:
              'I confirm that all donations in this spreadsheet meet the following requirements:',
            List1: 'They meet {0}, for claiming Gift Aid',
            List1Link: 'HMRC’s Gift Aid requirements',
            List1LinkHref:
              'https://www.gov.uk/claim-gift-aid/what-you-can-claim-it-on',
            List2:
              'They have not had Gift Aid claimed previously, either by our organisation or any third party (e.g. JustGiving, CAF etc)',
            List3:
              'They are authorised for Swiftaid to claim Gift Aid on, in full compliance with HMRC regulations, including the avoidance of double-claiming under the Gift Aid Small Donations Scheme (GASDS).',
          },
        },
      },
      Inactive: {
        ImageAlt: 'A spade digging into the ground with hearts coming out',
        Title: 'Find Missed Gift Aid',
        Para: 'The Great Gift Aid Hunt has uncovered millions of pounds in additional Gift Aid revenue for charities across the UK, making a meaningful difference.',
        Para2: 'Submissions for the current tax year are currently closed.',
        LinkText: 'Learn more',
        LinkHref: 'https://www.swiftaid.co.uk/finderguide/',
      },
      GafSubmissionsTable: {
        Header: 'Uploaded spreadsheets',
        Field1: 'File',
        Field2: 'Date uploaded',
        Field3: 'Donations',
        Field4: 'ID',
        Field5: 'Status',
        EmptyState: 'Files you upload will be displayed here',
        LoadingState: 'Checking for new files...',
        ErrorState: 'Error loading files {refresh}.',
        ErrorStateRefresh: 'Click here to try again',
        PopoverContent: {
          [GafSubmissionState.CHECKING]:
            'Swiftaid is checking your spreadsheet to make sure everything is in order.',
          [GafSubmissionState.CANCELLED]: 'The submission was cancelled.',
          [GafSubmissionState.PROCESSED]:
            'The submission was processed successfully.',
          [GafSubmissionState.READY]:
            'The submission is ready to be processed.',
        },
        ShowCommentButtonText: 'Show comment',
      },
    },
  },
  Generic: {
    CharityNavBar: {
      NavLogoImageAlt: 'Swiftaid logo',
      NotificationFeed: {
        Header: 'Notifications',
        ClearButton: 'Clear',
        NoNotificationMessage: 'Nothing to see here!',
        NoNotificationDesc: 'You don’t have any notifications.',
        NoDonationSourceMessage: 'No donation sources available',
        NoDonationSourceDesc:
          'Swiftaid won’t be able to process Gift Aid for your charity unless you add one.',
      },
      FeedItem: {
        PostedTimeAriaLabel: 'Posted time',
        Posted: 'Posted',
        MenuAriaLabel: 'Notification menu',
        RemoveButton: 'Remove',
        Removing: 'Removing...',
        LearnMoreButton: 'Learn more',
      },
    },
    NavBar: {
      NavLogoImageAlt: 'Swiftaid logo',
      ProfileMenuAriaLabel: 'Profile menu',
      Edit: 'Edit profile',
      Logout: 'Log out',
    },
    SideBar: {
      NavLogoImageAlt: 'Swiftaid logo',
      MenuItems: {
        GiftAid: 'Gift Aid',
        DonationSources: 'Donation sources',
        FindGiftAid: 'Gift Aid Finder',
        Exit: 'Exit',
      },
    },
    ComponentSpinner: {
      Loading: 'Loading...',
      LoadingData: 'Loading data',
    },
    EditProfileModal: {
      Title: 'Edit profile',
      Form: {
        AriaLabel: 'Please enter your full name',
        FirstNameInput: {
          Label: 'First name(s)',
          Placeholder: 'First name(s)',
        },
        LastNameInput: {
          Label: 'Last name',
          Placeholder: 'Last name',
        },
        Button: {
          Text: 'Save',
          Saving: 'Saving...',
        },
      },
    },
    InfoBox: {
      CloseButtonText: 'Close',
    },
    Error: {
      BadRequest: 'Bad request, please try again.',
      Unauthorised: 'You are unauthorised, please login again.',
      Network: 'Unable to connect to server, please try again.',
      NotFound: 'Sorry could not find this entry, please try again.',
      Forbidden: 'Access denied please try again.',
      Conflict: 'There was a conflict please try again.',
      ServiceUnavailable: 'Service unavailable, please check back soon.',
      CsvDownloadError:
        'An error occurred. Please try again or contact support.',
    },
  },
}
