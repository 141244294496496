<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { SET_FIRST_NAME, SET_LAST_NAME } from '@/store/mutation-types'
import { userInformationSchema } from '@/types'

const store = useStore()

const loading = ref(false)

const user = computed(() => store.getters.user)

const emit = defineEmits(['is-open'])

const validationSchema = toTypedSchema(userInformationSchema)
const { handleSubmit, errors, defineField } = useForm({
  validationSchema,
  validateOnMount: false,
})
const [firstname] = defineField('firstname')
const [lastname] = defineField('lastname')

const putUserDetails = handleSubmit(async (values) => {
  loading.value = true

  try {
    await store.dispatch('putUserDetails', {
      firstname: values.firstname,
      lastname: values.lastname,
    })

    store.commit(SET_FIRST_NAME, values.firstname)
    store.commit(SET_LAST_NAME, values.lastname)
  } catch (error) {
    console.error(error)
  }
  document.getElementById('closeModal')?.click()
  loading.value = false
})

onMounted(() => {
  const modalElement = document.getElementById('editProfileModal')

  modalElement?.addEventListener('show.bs.modal', () => {
    emit('is-open', true)
  })

  modalElement?.addEventListener('hide.bs.modal', () => {
    emit('is-open', false)
  })

  if (user.value?.firstname && user.value?.lastname) {
    firstname.value = user.value.firstname
    lastname.value = user.value.lastname
  }
})
</script>

<template>
  <button
    class="m-0 p-3 btn text-start rounded-0 fw-normal"
    data-bs-toggle="modal"
    data-bs-target="#editProfileModal"
  >
    {{ $t('Generic.NavBar.Edit') }}
  </button>

  <Teleport to="#app">
    <div
      v-if="user"
      id="editProfileModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editProfileModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="editProfileModalTitle" class="modal-title">
              {{ $t('Generic.EditProfileModal.Title') }}
            </h5>
            <button
              id="closeModal"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form
              id="editProfileForm"
              :aria-label="$t('Generic.EditProfileModal.Form.AriaLabel')"
              novalidate
              spellcheck="false"
              role="form"
              @submit.prevent="putUserDetails"
            >
              <div class="mb-3">
                <label for="firstname" class="form-label fw-semibold">
                  {{ $t('Generic.EditProfileModal.Form.FirstNameInput.Label') }}
                </label>
                <input
                  v-model="firstname"
                  id="firstname"
                  :class="{
                    input: true,
                    'is-invalid': errors.firstname?.length,
                  }"
                  :placeholder="
                    $t(
                      'Generic.EditProfileModal.Form.FirstNameInput.Placeholder'
                    )
                  "
                  type="text"
                  class="form-control"
                  name="firstname"
                  aria-describedby="firstname"
                />
                <span
                  v-show="errors.firstname?.length"
                  class="text-danger small input-error"
                  data-testid="firstname-error"
                >
                  {{ errors.firstname }}
                </span>
              </div>

              <div class="mb-3">
                <label for="lastname" class="form-label fw-semibold">
                  {{ $t('Generic.EditProfileModal.Form.LastNameInput.Label') }}
                </label>
                <input
                  v-model="lastname"
                  id="lastname"
                  :class="{
                    input: true,
                    'is-invalid': errors.lastname?.length,
                  }"
                  :placeholder="
                    $t(
                      'Generic.EditProfileModal.Form.LastNameInput.Placeholder'
                    )
                  "
                  class="form-control"
                  name="lastname"
                  aria-describedby="lastname"
                />
                <span
                  v-show="errors.lastname?.length"
                  class="text-danger small input-error"
                  data-testid="lastname-error"
                >
                  {{ errors.lastname }}
                </span>
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>

            <button
              :disabled="loading"
              class="btn btn-primary"
              type="submit"
              form="editProfileForm"
            >
              {{
                loading
                  ? $t('Generic.EditProfileModal.Form.Button.Saving')
                  : $t('Generic.EditProfileModal.Form.Button.Text')
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
