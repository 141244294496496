<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import ErrorBar from '@/components/generic/ErrorBar.vue'
import AppLoader from '@/components/generic/AppLoader.vue'
import ChatbotClearance from '@/components/generic/ChatbotClearance.vue'
import useCookieConsent from '@/compositions/useCookieConsent'

const store = useStore()

useCookieConsent()

const loading = computed(() => store.state.app.loading)
</script>

<template>
  <ErrorBar />

  <Transition name="page-fade" mode="out-in" appear>
    <AppLoader v-if="loading" />
  </Transition>

  <router-view v-slot="{ Component }">
    <Transition name="page-fade" mode="out-in" appear>
      <component :is="Component" />
    </Transition>
  </router-view>

  <ChatbotClearance />
</template>

<style lang="scss">
@use '@/sass/main.scss';
</style>
