<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import FeedItem from '@/components/generic/CharityNavBar/FeedItem.vue'
import { NotificationSeverity } from '@/types'
import type { Notification } from '@/types'
import debug from '@/helpers/debug'
import { useCloseMenuOnClickOutside } from '@/compositions/useCloseMenuOnClickOutside'
import { CLOSE_FEED_MENU } from '@/store/mutation-types'

const store = useStore()
const route = useRoute()
useCloseMenuOnClickOutside(
  'notification-feed-menu',
  'notification-menu-button',
  () => {
    store.commit(CLOSE_FEED_MENU)
  }
)

type NotificationWithRemove = Notification & { isRemoving?: boolean }

const sortedNotifications = ref<NotificationWithRemove[]>([])

const sources = computed(() => store.getters.sources)
const noDonationSourceNotification = computed(() => sources.value?.length === 0)
const notifications = computed(() => store.getters.notifications)
const noNotifications = computed(
  () => !noDonationSourceNotification.value && notifications.value?.length === 0
)
const errorNotifications = computed(() => {
  return store.getters.notifications.filter(
    (notification: Notification) =>
      notification.severity === NotificationSeverity.Error
  )
})
const infoNotifications = computed(() => {
  return store.getters.notifications.filter(
    (notification: Notification) =>
      notification.severity !== NotificationSeverity.Error
  )
})
const charityId = computed(() => route.params.id)

const clearAll = () => {
  for (const notification of notifications.value) {
    if (notification.severity === NotificationSeverity.Error) continue

    store.dispatch('deleteNotification', {
      charityId: charityId.value,
      id: notification.id,
    })
  }

  emit('close')
}

const sortByTime = (a: Notification, b: Notification) => {
  const valA = Date.parse(a.time)
  const valB = Date.parse(b.time)
  return valB - valA
}

const sortNotifications = async () => {
  try {
    sortedNotifications.value = [
      ...errorNotifications.value.sort(sortByTime),
      ...infoNotifications.value.sort(sortByTime),
    ]
  } catch (error) {
    debug('Error sorting notifications', error)
  }
}

const removeNotification = (id: string) => {
  const notification = sortedNotifications.value.find(
    (notification) => notification.id === id
  )

  if (!notification) return

  notification.isRemoving = true

  setTimeout(() => {
    sortedNotifications.value = sortedNotifications.value.filter(
      (notification) => notification.id !== id
    )
  }, 300)
}

onMounted(() => {
  sortNotifications()
})

const emit = defineEmits(['close'])
</script>

<template>
  <div
    id="notification-feed-menu"
    class="feed-wrapper"
    @click.stop
    data-testid="notification-feed-menu"
  >
    <header
      class="feed-header d-flex justify-content-between align-items-center"
    >
      <h5 class="mb-0">
        {{ $t('Generic.CharityNavBar.NotificationFeed.Header') }}
      </h5>
      <a
        v-if="infoNotifications && infoNotifications.length > 0"
        id="feedMark"
        href="#"
        class="mark-all"
        @click.prevent="clearAll"
      >
        {{ $t('Generic.CharityNavBar.NotificationFeed.ClearButton') }}
      </a>
    </header>

    <section
      v-if="noNotifications"
      id="noNotificationFeed"
      data-testid="no-notification"
    >
      <article class="p-3">
        <h6>
          {{
            $t('Generic.CharityNavBar.NotificationFeed.NoNotificationMessage')
          }}
        </h6>
        <p class="mb-0">
          {{ $t('Generic.CharityNavBar.NotificationFeed.NoNotificationDesc') }}
        </p>
      </article>
    </section>

    <section
      v-if="noDonationSourceNotification"
      data-testid="no-donation-source-notification"
      class="bg-danger-subtle"
    >
      <article class="p-3">
        <h6>
          {{
            $t('Generic.CharityNavBar.NotificationFeed.NoDonationSourceMessage')
          }}
        </h6>
        <p class="mb-0">
          {{
            $t('Generic.CharityNavBar.NotificationFeed.NoDonationSourceDesc')
          }}
        </p>
      </article>
    </section>

    <section
      v-for="notification in sortedNotifications"
      :key="notification.id"
      class="notification-item"
      :class="{ 'notification-removing': notification.isRemoving }"
    >
      <FeedItem
        :notification="notification"
        :data-testid="
          notification.severity === NotificationSeverity.Error
            ? 'error-notification'
            : 'info-notification'
        "
        @close="$emit('close')"
        @removedNotificationId="removeNotification"
      />
    </section>
  </div>
</template>

<style lang="scss" scoped>
.feed-wrapper {
  position: absolute;
  width: 400px;
  height: unset;
  max-height: 710px;
  top: 49px;
  right: 0;
  border: 0;
  overflow-y: auto;
  -webkit-overflow-y: auto;
  margin: 0;
  padding: 0;
  display: inline-grid;

  @media (max-width: 576px) {
    width: 88vw;
    right: 0;
    top: 64px;
    overflow: none;
  }
}

.feed-header {
  height: 50px;
  padding: 15px;
  border-bottom: solid 1px var(--light-grey);
}

.mark-all {
  color: var(--black);
  font-size: 10px;
  font-weight: bold;
  z-index: 1;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.2s ease;
  white-space: nowrap;
  text-align: right;
  transition: all 0.2s;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;

  &:after {
    content: '';
  }

  &:hover {
    color: var(--mid-grey);
    width: 150px;

    &:after {
      content: ' ALL NOTIFICATIONS';
    }
  }
}

.notification-item {
  transition: all 0.3s ease;
  max-height: 500px;
  opacity: 1;
}

.notification-removing {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  background-color: var(--bs-gray-500);
}
</style>
