<script setup lang="ts">
defineProps({
  notificationsCount: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits(['toggleFeed'])
</script>

<template>
  <button
    id="notification-menu-button"
    class="notification-menu-button btn btn-link rounded-circle p-0"
    data-testid="notification-menu-button"
    @click="emit('toggleFeed')"
  >
    <div
      class="notification-image-placeholder d-flex align-items-center justify-content-center"
    >
      <i class="bi bi-bell-fill fs-4"></i>
      <div v-if="notificationsCount" class="notification">
        {{ notificationsCount }}
      </div>
    </div>
  </button>
</template>

<style lang="scss" scoped>
.btn.notification-menu-button {
  background-color: var(--navbar-button-bg);
}

.btn.notification-menu-button:hover,
.btn.notification-menu-button:focus,
.btn.notification-menu-button:active {
  background-color: var(--navbar-button-bg-hover);
}

.notification-image-placeholder {
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.notification-image-placeholder i {
  transition: color 0.2s ease;
  color: var(--black);
}

.notification {
  height: 17px;
  width: 17px;
  border: solid 1px var(--white);
  border-radius: 100%;
  background-color: var(--theme-red);
  position: absolute;
  top: -5px;
  right: -5px;
  color: var(--white);
  font-size: 10px;
  text-align: center;
  font-weight: bold;
}
</style>
