<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import ProfileMenuButton from '@/components/shared/ProfileMenuButton.vue'
import ProfileImage from '@/components/shared/ProfileImage.vue'
import ProfileMenu from '@/components/shared/ProfileMenu.vue'
import InfoBox from '@/components/generic/InfoBox.vue'
import { TOGGLE_MENU } from '@/store/mutation-types'

const store = useStore()
const router = useRouter()

const user = computed(() => store.getters.user)
const menu = computed(() => store.getters.menu)
const isNotLoginOrDetailsPage = computed(() => {
  const currentRouteName = router.currentRoute.value.name
  return currentRouteName !== 'Login' && currentRouteName !== 'Details'
})

const toggleMenu = () => store.commit(TOGGLE_MENU)

const edit = () => {
  toggleMenu()
}

const logout = async () => {
  toggleMenu()
  await store.dispatch('auth0Logout')
}
</script>

<template>
  <nav class="navbar">
    <div class="container-fluid mx-3">
      <a
        class="navbar-brand btn btn-link p-0 border-0 rounded-2"
        href="https://swiftaid.co.uk/charity/"
      >
        <img
          :alt="$t('Generic.NavBar.NavLogoImageAlt')"
          src="@/assets/images/logo.svg"
        />
      </a>

      <div
        v-if="isNotLoginOrDetailsPage"
        class="navbar-profile-wrapper position-relative d-flex justify-content-center"
      >
        <ProfileMenuButton @click="toggleMenu">
          <ProfileImage
            id="navbarProfile"
            :firstname="user.firstname"
            :lastname="user.lastname"
            :email="user.emailAddress"
            :aria-label="$t('Generic.NavBar.ProfileMenuAriaLabel')"
            data-testid="profile-placeholder"
          />
        </ProfileMenuButton>
        <transition name="menu-fade">
          <InfoBox v-if="menu" id="profileMenu" @close="toggleMenu">
            <template #content>
              <ProfileMenu
                :firstname="user.firstname"
                :lastname="user.lastname"
                :email="user.emailAddress"
                @edit="edit"
                @logout="logout"
              />
            </template>
          </InfoBox>
        </transition>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.navbar {
  .navbar-container {
    width: 960px;
    margin: auto;
  }
}

a {
  text-decoration: none;
  font-weight: 600;
  color: var(--black);
  font-size: 20px;
}
</style>
