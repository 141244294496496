<script setup lang="ts">
import { ref } from 'vue'
import { useStore } from 'vuex'
import ProfileImage from '@/components/shared/ProfileImage.vue'
import { useCloseMenuOnClickOutside } from '@/compositions/useCloseMenuOnClickOutside'
import { CLOSE_MENU } from '@/store/mutation-types'
import EditProfileModal from '@/components/generic/EditProfileModal.vue'

const store = useStore()

const isEditProfileModalOpen = ref(false)

useCloseMenuOnClickOutside('profile-menu', 'profile-menu-button', () => {
  if (isEditProfileModalOpen.value) return
  store.commit(CLOSE_MENU)
})

defineProps<{
  firstname?: string
  lastname?: string
  email?: string
}>()

defineEmits(['edit', 'logout'])
</script>

<template>
  <div id="profile-menu" class="shadow">
    <div
      v-if="firstname"
      class="d-flex align-items-center p-3 gap-2 border-bottom"
      data-testid="profile-menu-username"
    >
      <Suspense>
        <ProfileImage
          :firstname="firstname"
          :lastname="lastname"
          :email="email"
          size="small"
        />
      </Suspense>
      <p class="m-0 h6">{{ firstname }} {{ lastname }}</p>
    </div>
    <div class="d-flex flex-column">
      <EditProfileModal @is-open="isEditProfileModalOpen = $event" />
      <button
        class="m-0 p-3 btn text-start rounded-0 fw-normal"
        @click="$emit('logout')"
      >
        {{ $t('Generic.NavBar.Logout') }}
      </button>
    </div>
  </div>
</template>

<style scoped>
button,
:deep(button) {
  border: none;
}

button:active,
button:focus,
button:hover,
:deep(button):active,
:deep(button):focus,
:deep(button):hover {
  background-color: var(--light-grey);
  border-color: var(--light-grey);
}
</style>
