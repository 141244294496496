async function hashEmail(email: string) {
  const encoder = new TextEncoder()
  const data = encoder.encode(email.trim().toLowerCase())
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray
    .map((b) => ('00' + b.toString(16)).slice(-2))
    .join('')
  return hashHex
}

export async function generateGravatarUrl(email: string | undefined) {
  if (!email) return null

  const hash = await hashEmail(email)
  return `https://www.gravatar.com/avatar/${hash}?d=blank`
}
