import axios, { type InternalAxiosRequestConfig } from 'axios'
import store from '@/store'
import router from '@/router'
import debug from '@/helpers/debug'
import { GLOBAL_ERROR, MAINTENANCE } from '@/store/mutation-types'

declare module 'axios' {
  export interface AxiosRequestConfig {
    skipAuthHeader?: boolean
  }
  export interface InternalAxiosRequestConfig {
    skipAuthHeader?: boolean
  }
}

axios.defaults.baseURL = import.meta.env.VITE_SWIFTAID_API_URL

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    debug('request', config)

    if (config.skipAuthHeader) {
      return config
    }

    config.headers.set(
      'Authorization',
      // @ts-ignore
      `Bearer ${store.state.auth.idToken}`
    )
    return config
  },
  (error) => {
    debug('request error', error)
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    debug('response', response)
    return response
  },
  (error) => {
    console.error(error, 'response error')
    if (error.message === 'Network Error') {
      if (!navigator.onLine) {
        store.commit(GLOBAL_ERROR, 'Generic.Error.Network')
      } else {
        router.replace({ name: 'Login' }).catch(() => {})
      }
    } else {
      switch (error.response.status) {
        case 400:
          store.commit(GLOBAL_ERROR, 'Generic.Error.BadRequest')
          break
        case 401:
          store.commit(GLOBAL_ERROR, 'Generic.Error.Unauthorised')
          router.replace({ name: 'Login' }).catch(() => {})
          break
        case 403:
          store.commit(GLOBAL_ERROR, 'Generic.Error.Forbidden')
          break
        case 404:
          store.commit(GLOBAL_ERROR, 'Generic.Error.NotFound')
          router.replace({ name: 'Dashboard' }).catch(() => {})
          break
        case 409:
          // No global error on donation source conflicts
          // @ts-ignore
          if (router.currentRoute.name !== 'DonationSources') {
            store.commit(GLOBAL_ERROR, 'Generic.Error.Conflict')
          }
          break
        case 503:
          store.commit(GLOBAL_ERROR, 'Generic.Error.ServiceUnavailable')
          store.commit(MAINTENANCE, true)
          router.replace({ name: 'Maintenance' }).catch(() => {})
          break
        default:
          store.commit(GLOBAL_ERROR, 'Generic.Error.Network')
      }
    }
    return Promise.reject(error)
  }
)
