<script setup lang="ts">
import { ref, computed, type PropType } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import InfoBox from '@/components/generic/InfoBox.vue'
import { dateToShortString } from '@/helpers/formats'
import type { Notification } from '@/types'

const props = defineProps({
  notification: {
    type: Object as PropType<Notification>,
    default: null,
  },
})

const emit = defineEmits(['close', 'removedNotificationId'])

const store = useStore()
const router = useRouter()
const route = useRoute()

const requestingDelete = ref(false)
const itemFeedMenuOpen = ref(false)
const charityId = route.params.id

const severityClass = computed(() => props.notification.severity)
const notificationTime = computed(() => {
  const time = dateToShortString(props.notification.time)

  if (time === 'Invalid DateTime') {
    return ''
  }

  return time
})

const toggleFeedItemMenu = () => {
  itemFeedMenuOpen.value = !itemFeedMenuOpen.value
}

const remove = async () => {
  if (requestingDelete.value) return

  requestingDelete.value = true

  try {
    await store.dispatch('deleteNotification', {
      charityId: charityId,
      id: props.notification.id,
    })
    emit('removedNotificationId', props.notification.id)
  } catch (error) {
    console.error(error)
  } finally {
    requestingDelete.value = false
  }
}

const sendTo = () => {
  if (!itemFeedMenuOpen.value) {
    const route = props.notification.category
    switch (route) {
      case 'donationSources':
        router.push({
          name: 'DonationSources',
          params: { id: charityId },
        })
        break
      case 'declaration':
        router.push(`/charity/${charityId}/declarations`)
        break
      case 'support':
        if (props.notification.url) {
          window.open(props.notification.url, '_blank')
        }
        break
      case 'settings':
        router.push(`/charity/${charityId}/settings`)
        break
      case 'billing':
        router.push(`/charity/${charityId}/billing`)
        break
      case 'link':
        window.open(props.notification.url, '_blank')
        break
    }
    emit('close')
  }
}
</script>

<template>
  <article
    :class="`feed-item--${severityClass}`"
    class="feed-item d-flex justify-content-between align-items-start gap-3 w-100 p-3 border-bottom position-relative"
  >
    <section @click="sendTo" class="cursor-pointer">
      <h6 class="text-start">{{ notification.message }}</h6>
      <p class="mb-0">{{ notification.description }}</p>
      <p
        v-if="notificationTime !== ''"
        :class="severityClass"
        class="post-time mt-3 text-uppercase mb-0 fw-bold"
        :aria-label="$t('Generic.CharityNavBar.FeedItem.PostedTimeAriaLabel')"
        data-testid="notificationTime"
      >
        {{ $t('Generic.CharityNavBar.FeedItem.Posted') }}
        {{ notificationTime }}
      </p>
    </section>

    <button
      v-if="notification.severity !== 'error' || notification.url"
      :class="severityClass"
      class="feed-item-menu-button btn btn-link p-0 border-0 rounded-0"
      data-testid="notification-menu"
      :aria-label="$t('Generic.CharityNavBar.FeedItem.MenuAriaLabel')"
      @click="toggleFeedItemMenu"
    >
      <i class="bi bi-three-dots fs-5 text-dark"></i>

      <transition name="menu-fade">
        <InfoBox
          v-if="
            (itemFeedMenuOpen && notification.severity !== 'error') ||
            (itemFeedMenuOpen && notification.url)
          "
          data-testid="itemFeedMenuOpen"
          class="shadow"
          @close="toggleFeedItemMenu"
        >
          <template #content>
            <div
              v-if="notification.severity !== 'error'"
              data-testid="feed-item-menu-remove"
              class="menu-link d-flex align-items-center justify-content-between"
              @click="remove"
            >
              <a
                href="#"
                class="btn btn-link rounded-0 w-100 text-start"
                :class="{ disabled: requestingDelete }"
              >
                <span v-if="!requestingDelete">
                  {{ $t('Generic.CharityNavBar.FeedItem.RemoveButton') }}
                </span>
                <span v-else>
                  {{ $t('Generic.CharityNavBar.FeedItem.Removing') }}
                </span>
              </a>
            </div>
            <div
              v-if="notification.url"
              class="menu-link"
              data-testid="feed-item-menu-learn"
            >
              <a
                :href="notification.url"
                class="btn btn-link rounded-0 w-100 text-start"
              >
                {{ $t('Generic.CharityNavBar.FeedItem.LearnMoreButton') }}
              </a>
            </div>
          </template>
        </InfoBox>
      </transition>
    </button>
  </article>
</template>

<style scoped>
.post-time {
  letter-spacing: 1px;
  font-size: 0.6rem;
}

.feed-item--error {
  background-color: var(--bs-danger-bg-subtle);
}

.info-box-wrapper {
  top: 40px;
  right: 5px;
  width: auto;
}
</style>
