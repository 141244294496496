import {
  REMOVE_NOTIFICATION_FROM_ARRAY,
  SET_TOTAL_DECLARATIONS,
  SET_TOTAL_GIFT_AID,
  SET_TOTAL_DONATIONS,
  SET_CLAIMS,
  SET_DECLARATIONS,
  SET_CLAIM_SUMMARY,
  SET_DONATION_SOURCES,
  SET_DONATION_SOURCE,
  SET_USER,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_CHARITY,
  SET_CHARITY_ID,
  SET_CHARITY_NAME,
  SET_NOTIFICATIONS,
  SET_FRIENDLY_NAME,
  SET_SOURCE_DETAILS_MODAL_VISIBILITY,
  SET_DONATION_SOURCE_SETUP_MODAL_VISIBILITY,
  SET_GAF_SUBMISSIONS,
} from '@/store/mutation-types'
import type {
  UserInformation,
  NomineeAssignment,
  Notification,
  DonationSource,
} from '@/types'

export default {
  [REMOVE_NOTIFICATION_FROM_ARRAY](state: any, id: string) {
    const array = state.notifications
    const newArray: any[] = []
    array.forEach((val: any) => {
      if (val.id !== id) {
        newArray.push(val)
      }
    })
    state.notifications = newArray
  },

  [SET_TOTAL_DECLARATIONS](state: any, payload: number) {
    state.totals.countDeclarations = payload
  },

  [SET_TOTAL_GIFT_AID](state: any, payload: number) {
    state.totals.totalGiftAid = payload
  },

  [SET_TOTAL_DONATIONS](state: any, payload: number) {
    state.totals.totalDonations = payload
  },

  [SET_CLAIMS](state: any, payload: any) {
    state.claims = payload.data
  },

  [SET_DECLARATIONS](state: any, payload: any) {
    state.declarations = payload
  },

  [SET_CLAIM_SUMMARY](state: any, payload: any) {
    state.claimSummary = payload
  },

  [SET_DONATION_SOURCES](state: any, payload: any) {
    state.donationSources = payload
  },

  [SET_DONATION_SOURCE](state: any, payload: DonationSource) {
    state.donationSource = payload
  },

  [SET_USER](state: any, payload: UserInformation) {
    state.user = payload
  },

  [SET_FIRST_NAME](state: any, firstname: string) {
    state.user.firstname = firstname
  },

  [SET_LAST_NAME](state: any, lastname: string) {
    state.user.lastname = lastname
  },

  [SET_CHARITY](state: any, payload: NomineeAssignment) {
    state.charity = payload
  },

  [SET_CHARITY_ID](state: any, payload: string) {
    state.charityId = payload
  },

  [SET_CHARITY_NAME](state: any, payload: string) {
    state.charityName = payload
  },

  [SET_NOTIFICATIONS](state: any, payload: Notification[]) {
    state.notifications = payload
  },

  [SET_FRIENDLY_NAME](state: any, friendlyName: string) {
    state.donationSource.friendlyName = friendlyName
  },

  [SET_SOURCE_DETAILS_MODAL_VISIBILITY](state: any, payload: boolean) {
    state.sourceDetailsModalVisibility = payload
  },

  [SET_DONATION_SOURCE_SETUP_MODAL_VISIBILITY](state: any, payload: boolean) {
    state.donationSourceSetupModalVisibility = payload
  },
  [SET_GAF_SUBMISSIONS](state: any, payload: any) {
    state.gafSubmissions = payload
  },
}
