<script setup lang="ts">
import { onBeforeMount, onUnmounted } from 'vue'
import NavBar from '@/components/generic/NavBar.vue'

onBeforeMount(() => {
  document.body.classList.add('bg-white')
})

onUnmounted(() => {
  document.body.classList.remove('bg-white')
})
</script>

<template>
  <div class="d-flex flex-column justify-content-between m-0">
    <NavBar />
    <div class="main">
      <div class="auth-content">
        <router-view role="main"></router-view>
      </div>
    </div>
  </div>
</template>
