<script lang="ts" setup>
import GiftAidSvg from '@/components/generic/SideNav/GiftAidSvg.vue'
import DonationSourcesSvg from '@/components/generic/SideNav/DonationSourcesSvg.vue'
import FindGiftAidSvg from '@/components/generic/SideNav/FindGiftAidSvg.vue'
import ExitSvg from '@/components/generic/SideNav/ExitSvg.vue'

defineProps<{
  icon: string
  title: string
  ariaDescribedBy: string
}>()

const iconMap = {
  GiftAidSvg,
  DonationSourcesSvg,
  FindGiftAidSvg,
  ExitSvg,
} as const

const addIcon = (icon: string) => {
  return iconMap[icon as keyof typeof iconMap] || icon
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="currentColor"
    viewBox="0 0 16 16"
    role="img"
    :aria-describedby="ariaDescribedBy"
  >
    <title :id="ariaDescribedBy">{{ $t(title) }}</title>
    <component :is="addIcon(icon)" />
  </svg>
</template>
