import mutations from './mutations'

export default {
  namespaced: false,
  state: {
    sidebarIsOpen: false,
    config: {
      pageInfo: {
        perPage: 50,
        pageNumber: 1,
      },
    },
    globalError: null,
    menu: false,
    feedMenu: false,
    maintenance: !!import.meta.env.VITE_MAINTENANCE,
    loading: true,
  },
  mutations,
}
