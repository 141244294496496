import { onMounted, onUnmounted } from 'vue'

export function useCloseMenuOnClickOutside(
  menuId: string,
  buttonId: string,
  closeMenu: () => void
) {
  const handleClickOutside = (event: MouseEvent) => {
    const menu = document.querySelector(`#${menuId}`)
    const menuButton = document.querySelector(`#${buttonId}`)

    if (
      menu &&
      !menu.contains(event.target as Node) &&
      !menuButton?.contains(event.target as Node)
    ) {
      closeMenu()
    }
  }

  onMounted(() => {
    document.addEventListener('click', handleClickOutside)
  })

  onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside)
  })
}
