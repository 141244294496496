import axios from 'axios'
import App from '@/store/modules/app'

export default {
  deleteNotification(payload: any) {
    return axios.delete(
      `/me/charity/${payload.charityId}/notifications/${payload.id}`
    )
  },
  fetchNextPage(payload: any) {
    return axios.get(`/me/charity/${payload.charityId}/declarations`, {
      params: {
        perPage: App.state.config.pageInfo.perPage,
        pageNumber: payload.pageNumber,
      },
    })
  },
  fetchCharitySummary(payload: any) {
    return axios.get(`/me/charity/${payload}`)
  },
  fetchClaim(payload: any) {
    return axios.get(
      `/me/charity/${payload.charityId}/claims/${payload.claimId}?perPage=${payload.perPage}&pageNumber=${payload.pageNumber}`
    )
  },
  fetchClaimCsvBreakdown(payload: any) {
    return axios.get(
      `/me/charity/${payload.charityId}/claims/${payload.claimId}`,
      {
        headers: {
          Accept: 'text/csv',
        },
      }
    )
  },
  fetchCurrentUser() {
    return axios.get(`/me`)
  },
  postUserDetails(payload: any) {
    return axios.post('/me/details', payload)
  },
  putUserDetails(payload: any) {
    return axios.put('/me/details', payload)
  },
  postCharityDetails(payload: any) {
    return axios.post('/me/charity', payload, {
      validateStatus: (status) => {
        return (status >= 200 && status < 300) || status === 409
      },
    })
  },
  patchSource(payload: any, charityId: string, donationSourceId: string) {
    return axios.patch(
      `/me/charity/${charityId}/donationSources/${donationSourceId}`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  },
  postGafSubmission(payload: {
    fileName: string
    charityId: string
    financialYear: string
  }) {
    return axios.post(
      `/me/charity/${payload.charityId}/gaf/financialyears/${payload.financialYear}/submissions`,
      {
        fileName: payload.fileName,
      }
    )
  },
  putSubmissionFile(
    uploadUrl: string,
    file: File,
    onProgress?: (progress: number) => void
  ) {
    return axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file.type,
        'x-ms-blob-type': 'BlockBlob',
      },
      skipAuthHeader: true,
      onUploadProgress: (progressEvent) => {
        if (onProgress && progressEvent.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          onProgress(percentCompleted)
        }
      },
    })
  },
  getGafSubmissions(payload: any) {
    return axios.get(
      `/me/charity/${payload.charityId}/gaf/financialyears/${payload.financialYear}/submissions`
    )
  },
}
