<template>
  <div class="app-loader position-fixed top-0 left-0 w-100 h-100 z-10">
    <div class="navbar d-flex justify-content-between align-items-center pulse">
      <div class="container-fluid mx-3 d-flex justify-content-end">
        <div class="profile-button-loader"></div>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center h-100 w-100 position-absolute top-0 left-0"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.app-loader {
  --loader-size: 7rem;
  --loader-border-width: 0.15rem;
  --loader-border-color: transparent;
  --loader-border-segment-color: var(--swiftaid-blue);
  --profile-button-size: 42px;

  backdrop-filter: brightness(0.95) saturate(0.5);
  background-color: rgba(var(--bs-body-bg-rgb), 0.5);
}

.z-10 {
  z-index: 10;
}

.pulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.25;
  }
}

.profile-button-loader {
  width: var(--profile-button-size);
  height: var(--profile-button-size);
  background-color: var(--bs-gray-300);
  border-radius: var(--profile-button-size);
}

.spinner-border {
  border-width: var(--loader-border-width);
  border-color: var(--loader-border-color);
  border-top-color: var(--loader-border-segment-color);
  width: var(--loader-size);
  height: var(--loader-size);
}
</style>
