import auth0 from '@/services/auth0'
import { delay } from 'lodash'
import { HELPERS } from '@/helpers'
import {
  UPDATE_AUTH,
  SET_AUTH,
  SET_TOKEN,
  GLOBAL_ERROR,
  SET_AUTH_EMAIL,
  SET_BUTTON_LOADING_STATUS,
  SET_FORM_SUBMITTED_STATUS,
} from '@/store/mutation-types'
import { AUTH_CONFIG } from '@/services/auth0/auth0-variables'
import { LoginMethod } from '@/types'

const resetAuthStateAndLocalStorage = (context: any) => {
  localStorage.removeItem('expires_at')
  localStorage.removeItem('loggedIn')
  context.commit(UPDATE_AUTH, false)
}

export default {
  storeMockToken: (context: any) => {
    localStorage.setItem('loggedIn', 'true')
    localStorage.setItem(
      'expires_at',
      JSON.stringify(10 * 1000 + new Date().getTime())
    )
    context.commit(SET_AUTH, true)
    context.commit(SET_TOKEN, 'swiftaid-testtoken-1234567890')
  },

  storeToken: (
    context: any,
    authResult: { expiresIn: number; accessToken: any }
  ) => {
    if (!authResult) throw new Error('authResult is required')

    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    )
    localStorage.setItem('loggedIn', 'true')
    localStorage.setItem('expires_at', expiresAt)
    context.commit(SET_TOKEN, authResult.accessToken)
    context.commit(SET_AUTH, true)
  },

  /**
   * Auth0 start passwordless login process
   *
   * payload = {
   *   codeLogin: true|false
   *   email: 'test@example.com'
   * }
   */
  auth0PasswordlessStart: (
    context: any,
    payload: { email: string; send: LoginMethod }
  ) => {
    return new Promise((resolve, reject) => {
      auth0.passwordlessStart(
        {
          connection: 'email',
          send: payload.send,
          email: payload.email,
        },
        function (err: any, res: any) {
          if (err) {
            context.commit(GLOBAL_ERROR, err.description)
            return reject(new Error(err))
          } else {
            resolve(res)
          }
        }
      )
    })
  },

  /**
   * Auth0 login with a verification code
   *
   * payload = {
   *   email: 'test@example.com',
   *   verificationCode: '111111111111'
   * }
   */
  auth0PasswordlessLogin: (
    context: any,
    payload: { email: any; verificationCode: any }
  ) => {
    return new Promise((resolve, reject) => {
      auth0.passwordlessLogin(
        {
          connection: 'email',
          email: payload.email,
          verificationCode: payload.verificationCode,
        },
        (err: any, res: any) => {
          if (err) {
            context.commit(GLOBAL_ERROR, err.description)
            return reject(new Error(err))
          } else {
            resolve(res)
          }
        }
      )
    })
  },

  auth0CheckSession: (context: any): Promise<void | { name: string }> => {
    return new Promise((resolve, reject) => {
      if (import.meta.env.MOCK_API) {
        context.dispatch('mockLogin').then(() => {
          resolve()
        })
        return
      }

      const timeout = setTimeout(() => {
        context.commit(
          GLOBAL_ERROR,
          'Timed out trying to contact the authentication server, please try again.'
        )
        reject()
      }, 10000)

      auth0.checkSession(
        {
          audience: AUTH_CONFIG.audience,
          scope: AUTH_CONFIG.scope,
        },
        async (err: any, authResult: any) => {
          clearTimeout(timeout)

          if (err) {
            // Not logged in
            // Reset auth state and localStorage
            resetAuthStateAndLocalStorage(context)
            reject(err)
            return
          }

          resolve(authResult)
        }
      )
    })
  },

  sendLoginEmail: async (context: any, payload: any) => {
    context.commit(SET_AUTH_EMAIL, payload.email)
    context.commit(SET_BUTTON_LOADING_STATUS, true)

    try {
      await context.dispatch('auth0PasswordlessStart', {
        email: payload.email,
        send: payload.send,
      })
      delay(() => {
        context.commit(SET_FORM_SUBMITTED_STATUS, true)
        context.commit(SET_BUTTON_LOADING_STATUS, false)
      }, 1000)
    } catch (error) {
      context.commit(SET_BUTTON_LOADING_STATUS, false)
    }
  },

  mockLogin: (context: any) => {
    localStorage.setItem('loggedIn', 'true')
    localStorage.setItem(
      'expires_at',
      JSON.stringify(10 * 1000 + new Date().getTime())
    )
    context.commit(SET_AUTH, true)
    context.commit(SET_TOKEN, 'swiftaid-testtoken-1234567890')
  },

  auth0Logout: (context: any) => {
    auth0.logout({
      returnTo: HELPERS.logoutUrl,
    })
    resetAuthStateAndLocalStorage(context)
  },
}
