import {
  TOGGLE_MOBILE_MENU,
  CLOSE_MOBILE_MENU,
  GLOBAL_ERROR,
  TOGGLE_MENU,
  CLOSE_MENU,
  TOGGLE_FEED_MENU,
  CLOSE_FEED_MENU,
  MAINTENANCE,
  SET_APP_LOADING,
} from '@/store/mutation-types'

const document = window.document

export default {
  [TOGGLE_MOBILE_MENU](state: any) {
    state.sidebarIsOpen = !state.sidebarIsOpen

    if (state.sidebarIsOpen) {
      document.body.classList.add('side-nav-open')
      window.scrollTo({ top: 0 })
    } else {
      document.body.classList.remove('side-nav-open')
    }
  },

  [CLOSE_MOBILE_MENU](state: any) {
    state.sidebarIsOpen = false
    document.body.classList.remove('side-nav-open')
  },

  [GLOBAL_ERROR](state: any, payload: any) {
    state.globalError = payload
  },

  [TOGGLE_MENU](state: any) {
    state.menu = !state.menu
  },

  [CLOSE_MENU](state: any) {
    state.menu = false
  },

  [TOGGLE_FEED_MENU](state: any) {
    state.feedMenu = !state.feedMenu
  },

  [CLOSE_FEED_MENU](state: any) {
    state.feedMenu = false
  },

  [MAINTENANCE](state: any, payload: any) {
    state.maintenance = payload
  },

  [SET_APP_LOADING](state: any, payload: any) {
    state.loading = payload
  },
}
