<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { generateGravatarUrl } from '@/compositions/useGravatarUrlGenerator'

const props = defineProps<{
  email?: string
  firstname?: string
  lastname?: string
  size?: 'small' | null
}>()

const loading = ref(true)
const loaded = ref(false)
const imgSrc = ref('')
const initials = computed(() => {
  if (!props.firstname || !props.lastname) return '?'
  const initials = `${props.firstname?.charAt(0)}${props.lastname?.charAt(0)}`
  return initials.toUpperCase()
})

const loadImage = async () => {
  try {
    imgSrc.value = (await generateGravatarUrl(props.email)) ?? ''
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
    setTimeout(() => {
      loaded.value = true
    }, 300)
  }
}

onMounted(() => {
  loadImage()
})
</script>

<template>
  <div
    class="profile-image-container position-relative"
    :class="{
      'small-placeholder': props.size === 'small',
      placeholder: !email,
      loading: loading,
    }"
  >
    <div
      class="profile-image-initials position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
      :class="{
        'opacity-0': loading,
      }"
    >
      {{ initials }}
    </div>

    <img
      v-if="loaded"
      :src="imgSrc"
      alt="Profile"
      class="position-relative"
      :class="{
        'opacity-0': loading,
      }"
    />
  </div>
</template>

<style>
.profile-image-container {
  --size: 42px;
  --current-size: var(--size);

  width: var(--current-size);
  height: var(--current-size);
  border-radius: var(--current-size);
}

.profile-image-container img {
  border-radius: var(--current-size);
  width: var(--current-size);
  height: var(--current-size);
  transition: opacity 0.3s ease-in-out;
}

.small-placeholder {
  --current-size: 28px;
  background-color: var(--light-mid-grey);
}

.small-placeholder .profile-image-initials {
  font-size: 12px;
  font-weight: 600;
}

.profile-image-initials {
  transition: opacity 0.3s ease-in-out;
  transition-delay: 0.3s;
  color: var(--black);
}
</style>
