<template>
  <button
    id="profile-menu-button"
    class="profile-menu-button btn btn-link rounded-circle p-0 d-flex align-items-center justify-content-center"
  >
    <Suspense>
      <slot />
    </Suspense>
  </button>
</template>

<style>
.btn.profile-menu-button {
  --btn-size: 42px;

  width: var(--btn-size);
  height: var(--btn-size);
  background-color: var(--navbar-button-bg);
}

.btn.profile-menu-button:hover,
.btn.profile-menu-button:focus,
.btn.profile-menu-button:active {
  background-color: var(--navbar-button-bg-hover);
}

.profile-menu-button img {
  transition: filter 0.3s ease;
  filter: brightness(1);
}

.profile-menu-button:hover img {
  filter: brightness(0.9);
}
</style>
